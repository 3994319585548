import './index.scss'

import * as React from 'react'
import { LangContext } from '../LanguageContext'
import TimeIcon from './assets/TimeIcon.svg'

export interface AgendaBoxProps {
  title: string,
  titleEn: string,
  description: string | string[],
  descriptionEn: string | string[],
  location: string,
  time: string,
}

export interface AgendaBoxState {
}

export default class AgendaBox extends React.Component<AgendaBoxProps, AgendaBoxState> {

  public render() {
    const { props } = this;

    return (
      <LangContext.Consumer>
        {(ctx) => (
          <div className="AgendaBox">
            <div className="content">
              {this.getText(ctx.lang).title}
              {this.getDescription(ctx.lang)}
              <div className="info">
                <span>
                  <img src={TimeIcon} />
                  {props.time}, {props.location}
                </span>
              </div>
            </div>
          </div>
        )}
      </LangContext.Consumer>
    );
  }

  private getDescription(lang: 'en' | 'ro') {
    const { description } = this.getText(lang)

    if (typeof description === 'string') {
      return description
    } else {
      return (
        <ul>
          {description.map((desc, i) => <li key={i}>{desc}</li>)}
        </ul>
      )
    }
  }

  private getText(lang: 'en' | 'ro'): { title: string, description: string | string[] } {
    if (lang === 'en') {
      return {
        title: this.props.titleEn,
        description: this.props.descriptionEn,
      }
    } else {
      return {
        title: this.props.title,
        description: this.props.description,
      }
    }
  }
}
