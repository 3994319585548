/**
 * Gallery display modal
 */

import * as React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import Modal from 'react-responsive-modal';
import ReactDropdown, { Option } from 'react-dropdown';
import { withPrefix } from 'gatsby';
import { GalleryImage } from '../../services/gallery/types';
import { getGalleryImages } from '../../services/gallery';
import 'react-dropdown/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './index.scss';
import { removeDuplicates, flatten } from '../../helpers/utils';

export interface GalleryProps {
  open: boolean;
  onClose: () => void;
}

interface GalleryState {
  images: GalleryImage[];
  yearFilter: string;
  tagFilter: string;
}

export default class Gallery extends React.Component<GalleryProps, any> {
  public state: GalleryState = {
    images: [],
    yearFilter: 'All',
    tagFilter: 'All',
  };

  public componentDidMount() {
    this.getData();
  }

  get years(): string[] {
    return [
      'All',
      '2010-2019',
      '2000-2010',
      '1990-2000',
      '1980-1990',
      '1970-1980',
      '1960-1970',
    ];
  }

  get tags(): string[] {
    return ['All', ...removeDuplicates(flatten(this.state.images.map((image) => image.tag.split(',')))).sort()];
  }

  get images(): ReactImageGalleryItem[] {
    const { yearFilter, tagFilter } = this.state;

    const filterByYear = (stateImage: GalleryImage): boolean => {
      if (yearFilter === 'All') {
        return true
      }

      const firstYear = parseInt(yearFilter.split('-')[0], 10)
      const lastYear = parseInt(yearFilter.split('-')[1], 10)

      return firstYear <= stateImage.year && stateImage.year <= lastYear
    }

    return this.state.images
      .filter(filterByYear)
      .filter((stateImage) => (tagFilter === 'All' ? true : stateImage.tag.includes(tagFilter)))
      .map((stateImage) => ({
        original: stateImage.image,
        thumbnail: stateImage.image,
      }));
  }

  public render() {
    const { tagFilter, yearFilter } = this.state;

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        classNames={{ modal: 'Gallery', closeButton: 'closeButton' }}
      >
        <header>
          <h1>Gallery</h1>
          <span>Tags:</span>
          <ReactDropdown options={this.tags} onChange={this.setTag} value={tagFilter} />
          <span>Years:</span>
          <ReactDropdown
            options={this.years}
            onChange={this.setYear}
            value={yearFilter !== 'All' ? yearFilter.toString() : yearFilter}
          />
        </header>

        {this.images.length === 0 ? (
          <h2 className="empty">No images matching filters </h2>
        ) : (
            <ImageGallery items={this.images} />
          )}
      </Modal>
    );
  }

  private setTag = (tag: Option) => {
    this.setState({ tagFilter: tag.value });
  };

  private setYear = (year: Option) => {
    this.setState({ yearFilter: year.value });
  };

  private async getData() {
    try {
      const images = await getGalleryImages();
      this.setState({ images });
    } catch (e) {
      setTimeout(this.getData, 2000);
    }
  }
}
