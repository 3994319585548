/**
 * Gallery API functions
 */

import { GalleryImage, APIGalleryImage } from './types';
import Axios from 'axios';

function convertYearAndIdToNumber(image: APIGalleryImage): GalleryImage {
  return { ...image, year: parseInt(image.year, 10), id: parseInt(image.id, 10) };
}

function convertNaNToDefaultNumber(image: GalleryImage): GalleryImage {
  return { ...image, year: isNaN(image.year) ? 0 : image.year, id: isNaN(image.id) ? 0 : image.id };
}

export async function getGalleryImages(): Promise<GalleryImage[]> {
  try {
    const response = await Axios.get('/api/gallery/');
    const images = response.data as APIGalleryImage[];
    return images.map(convertYearAndIdToNumber).map(convertNaNToDefaultNumber);
  } catch (e) {
    throw new Error(`Error getting gallery images: ${e}`);
  }
}
