import * as React from 'react';

export interface LanguageContextState {
  lang: 'ro' | 'en';
}

export type LanguageContextData = LanguageContextState & {
  setLang: (lang: 'ro' | 'en') => void;
};

const defaultContext: LanguageContextData = { lang: 'en', setLang: () => null };

export const LangContext = React.createContext(defaultContext);

export default class LanguageContext extends React.Component<any, LanguageContextState> {
  public state: LanguageContextState = {
    lang: 'en',
  };

  public componentDidMount() {
    const lsLang = localStorage.getItem('lang');

    if (lsLang === 'ro') {
      this.setState({ lang: 'ro' });
    }
  }

  public render() {
    return (
      <LangContext.Provider value={{ lang: this.state.lang, setLang: this.setLang }}>
        {this.props.children}
      </LangContext.Provider>
    );
  }

  private setLang = (lang: 'ro' | 'en') => {
    this.setState({ lang });
    localStorage.setItem('lang', lang);
  };
}
