import './index.scss'

/**
 * Main page
 */
import { Link } from 'gatsby'
import * as React from 'react'
import IconCalendar from '../assets/IconCalendar.svg'
import IconLocation from '../assets/IconLocation.svg'
import Logo50 from '../assets/Logo50.svg'
import LogoACS from '../assets/LogoACS.svg'
import LogoCS from '../assets/LogoCS.svg'
import LogoUPB from '../assets/LogoUPB.png'
import Ro100 from '../assets/Ro100.png'
import Button from '../atoms/Button'
import LanguageContext, { LangContext } from '../atoms/LanguageContext'
import LanguageSelector from '../atoms/LanguageSelector'
import Agenda from '../components/Agenda'
import Gallery from '../components/Gallery'
import Partners from '../components/Partners'

interface HomeState {
  agendaOpen: boolean;
  galleryOpen: boolean;
}

export default class Home extends React.Component<{}, HomeState> {
  public state: HomeState = {
    agendaOpen: false,
    galleryOpen: false,
  };

  public openAgenda = () => this.setState({ agendaOpen: true });
  public closeAgenda = () => this.setState({ agendaOpen: false });
  public openGallery = () => this.setState({ galleryOpen: true });
  public closeGallery = () => this.setState({ galleryOpen: false });
  public openRegistration = () =>
    window.open(
      'https://www.eventbrite.com/e/semicentenarul-departamentului-de-calculatoare-upb-tickets-56959052058',
      '_blank',
    );

  public render() {
    const { agendaOpen, galleryOpen } = this.state;

    return (
      <LanguageContext>
        <div className="MainPage">
          <header>
            <img src={LogoUPB} />
            <img src={LogoACS} />
            <LanguageSelector />
            <img src={Ro100} />
          </header>
          <main>

            <div className="logos">
              <img src={Logo50} />
              <img src={LogoCS} />
            </div>

            <LangContext.Consumer>
              {(ctx) => (
                <div className="dates">
                  <div className="icon">
                    <img src={IconLocation} />
                    <div className="text">
                      <span>
                        {
                          ctx.lang === 'en'
                            ? <><p>Aula UPB: 9:30-14:00</p><p>PRECIS: 14:30-17:30</p></>
                            : <><p>Aula UPB: 9:30-14:00</p><p>PRECIS: 14:30-17:30</p></>
                        }
                      </span>
                    </div>
                  </div>
                  <div className="icon">
                    <div className="text">
                      <span>31 {ctx.lang === 'en' ? 'May' : 'Mai'}</span>
                      <span>2019</span>
                    </div>
                    <img src={IconCalendar} />
                  </div>
                </div>
              )}
            </LangContext.Consumer>

            <LangContext.Consumer>
              {(ctx) => (
                <div className="buttons">
                  <Button onClick={this.openAgenda}>{ctx.lang === 'en' ? 'Agenda' : 'Agendă'}</Button>
                  <Button
                    onClick={this.openRegistration}
                    className="primary"
                    data-subtext={ctx.lang === 'en' ? 'Free via EventBrite' : 'Gratuit via EventBrite'}
                  >
                    Register
                  </Button>
                  <Button onClick={this.openGallery}>{ctx.lang === 'en' ? 'Gallery' : 'Galerie'}</Button>
                  <Link to="/community">
                    <Button>{ctx.lang === 'en' ? 'Community' : 'Comunitate'}</Button>
                  </Link>
                  {/*<Link to="/milestones">
                    <Button>{ctx.lang === 'en' ? 'Milestones' : 'Istoric'}</Button>
              </Link>*/}
                </div>
              )}
            </LangContext.Consumer>
          </main>
          <Partners />
        </div>
        <Agenda open={agendaOpen} onClose={this.closeAgenda} />
        <Gallery open={galleryOpen} onClose={this.closeGallery} />
      </LanguageContext>
    );
  }
}
