import * as React from 'react';
import en from './assets/en.svg';
import ro from './assets/ro.svg';

import './index.scss';
import { LangContext } from '../LanguageContext';

export interface LanguageSelectorProps {}

export default class LanguageSelector extends React.Component<LanguageSelectorProps, any> {
  public render() {
    return (
      <LangContext.Consumer>
        {(ctx) => {
          const setLangEn = () => ctx.setLang('en');
          const setLangRo = () => ctx.setLang('ro');
          return (
            <div className="LanguageSelector">
              <span>{ctx.lang === 'en' ? 'Select language' : 'Alege limba'}</span>
              <div className="images">
                <img src={en} className={`en ${ctx.lang === 'en' && 'selected'}`} onClick={setLangEn} />
                <img src={ro} className={`ro ${ctx.lang === 'ro' && 'selected'}`} onClick={setLangRo} />
              </div>
            </div>
          );
        }}
      </LangContext.Consumer>
    );
  }
}
