import './index.scss'

/**
 * Agenda display modal
 */
import * as React from 'react'
import Modal from 'react-responsive-modal'
import Logo50 from '../../assets/Logo50.svg'
import AgendaBox, { AgendaBoxProps } from '../../atoms/AgendaBox'
import Button from '../../atoms/Button'

export interface AgendaProps {
  open: boolean
  onClose: () => void
}

const PROGRAM: AgendaBoxProps[] = [
  {
    title: 'Întâmpinarea invitaților',
    titleEn: 'Guests reception',
    description: '',
    descriptionEn: '',
    location: 'Holul Aulei UPB',
    time: '9:00-9:30',
  },
  {
    title: 'Prima sesiune',
    titleEn: 'First session',
    description: ['Moment muzical cu Formația de Cameră a Orchestrei Inginerilor',
      'Prezentarea istoriei departamentului',
      'Unde suntem astăzi',
      'Cuvântul studenților',
      'Salulutul oficialităților',
    ],
    descriptionEn: [
      'Musical moment with the House Formation of the Engineer\'s Orchestra',
      'Department history presentation',
      'Where we are today',
      'Students\' word',
      'Salute of officials',
    ],
    location: 'Aula UPB',
    time: '9:30-11:45',
  },
  {
    title: 'Pauză de cafea',
    titleEn: 'Coffe break',
    description: '',
    descriptionEn: '',
    location: 'Holul Aulei UPB',
    time: '11:45-12:30',
  },
  {
    title: 'Sesiunea a doua',
    titleEn: 'Second session',
    description: [
      'Momentul sponsorilor',
      'Luări de cuvânt:',
      'Reprezentanți universități și institute',
      'Alumni',
      'Reprezentanți firme',
    ],
    descriptionEn: [
      'Sponsors moment',
      'Discourses:',
      'University and insititutes representants',
      'Alumni',
      'Company representants',
    ],
    location: 'Aula UPB',
    time: '12:30-14:15',
  },
  {
    title: 'Masa de prânz',
    titleEn: 'Lunch',
    description: '',
    descriptionEn: '',
    location: 'Clădirea PRECIS, etaj 1',
    time: '14:30-15:30',
  },
  {
    title: 'Vizitarea departamentului',
    titleEn: 'Department visit',
    description: [
      'Sesiune postere doctoranzi: PRECIS hol parter',
      'Vizitarea muzeului: PRECIS sala 101',
      'Schimb de experiență informal: PRECIS, hol et 1',
      'Vizitarea unor laboratoare',
    ],
    descriptionEn: [
      'PhD candidates Poster Session: PRECIS ground floor hall',
      'Museum visit: PRECIS room 101',
      'Informal experience exchange: PRECIS first floor hall',
      'Laboratory visits',
    ],
    location: 'Clădirea PRECIS',
    time: '15:30-17:30',
  },
  {
    title: 'Prezentarea centrelor de cercetare (PRECIS, CNTI, CRC)',
    titleEn: 'Research centers presentation (PRECIS, CNTI, CRC)',
    description: '',
    descriptionEn: '',
    location: 'PRECIS 105',
    time: '16:00-16:45',
  },
]

export default class Agenda extends React.Component<AgendaProps, any> {
  public render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        classNames={{ modal: 'Agenda', closeButton: 'closeButton' }}
      >
        <header>
          <h1>Agenda</h1>
          <img src={Logo50} />
        </header>
        <a href="/harta.png" target="_blank">
          <Button>Harta</Button>
        </a>
        <div className="agendaWrapper">
          {PROGRAM.map((props, i) => <AgendaBox key={i} {...props} />)}
        </div>
      </Modal >
    );
  }
}
