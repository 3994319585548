import * as React from 'react';
import './index.scss'

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
}

export default class Button extends React.Component<ButtonProps, any> {
  public render() {
    return (
      <button {...this.props} className={`Button ${this.props.className || ''}`} />
    );
  }
}
