import * as React from 'react';
import Helmet from '../atoms/Helmet';
import ExtraPagesMain from '../components/ExtraPages/Main';
import { getData } from '../services';
import { ImageBoxData } from '../services/types';
import LanguageContext, { LangContext } from '../atoms/LanguageContext';
export interface CommunityProps { }

export interface CommunityState {
  isLoading: boolean;
  entries: ImageBoxData[];
}

export default class Community extends React.Component<CommunityProps, CommunityState> {
  public state: CommunityState = {
    isLoading: false,
    entries: [],
  };

  public async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const entries = await getData('community');
      this.setState({ entries, isLoading: false });
    } catch (e) {
      // window.location.reload()
    }
  }

  public render() {
    const { isLoading, entries } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <LanguageContext>
        <LangContext.Consumer>
          {(ctx) => (
            <>
              <Helmet />
              <ExtraPagesMain
                title={ctx.lang === 'en' ? 'Community' : 'Comunitate'}
                data={entries}
                itemType={ctx.lang === 'en' ? 'members active' : 'membrii activi'}
                lang={ctx.lang}
              />
            </>
          )}
        </LangContext.Consumer>
      </LanguageContext>
    );
  }
}
