import './index.scss'

import * as React from 'react'
import Fitbit from './assets/fitbit.png'
import Keysight from './assets/keysight.png'
import LSAC from './assets/lsac.png'
import TechLounge from './assets/TechLounge.png'
import Thales from './assets/thales.png'
import UIPath from './assets/uipath.jpg'

export default class Partners extends React.Component<any, any> {
  public render() {
    return (
      <div className="Partners">
        <div className="block">
          <h1>Strategic Partners</h1>
          <img src={Keysight} />
          <img src={Fitbit} />
          <img src={UIPath} />
        </div>
        <div className="block">
          <h1>Partner</h1>
          <img src={Thales} />
        </div>
        <div className="block">
          <h1>Friends</h1>
          <img src={TechLounge} />
          <img className="lsac" src={LSAC} />
        </div>
      </div>
    );
  }
}
