export function removeDuplicates(original: string[]) {
  return original.map((v) => v.trim()).filter((v, i) => original.indexOf(v) === i);
}

export function flatten(input: string[][] | string[]): string[] {
  const stack = [...input];
  const res = [];

  while (stack.length) {
    // pop value from stack
    const next = stack.pop();

    if (next === undefined) {
      continue;
    }

    if (Array.isArray(next)) {
      // push back array items, won't modify the original input
      stack.push(...next);
    } else {
      res.push(next);
    }
  }
  // reverse to restore input order
  return res.reverse();
}
