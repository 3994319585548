// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-community-tsx": () => import("/builds/systemscspubro/50.cs.pub.ro/src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/systemscspubro/50.cs.pub.ro/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-milestones-tsx": () => import("/builds/systemscspubro/50.cs.pub.ro/src/pages/milestones.tsx" /* webpackChunkName: "component---src-pages-milestones-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/systemscspubro/50.cs.pub.ro/.cache/data.json")

