import * as React from 'react';
import Helmet from '../atoms/Helmet';
import ExtraPagesMain from '../components/ExtraPages/Main';
import { getData } from '../services';
import { ImageBoxData } from '../services/types';
import LanguageContext, { LangContext } from '../atoms/LanguageContext';

export interface MilestonesProps {}

export interface MilestonesState {
  isLoading: boolean;
  entries: ImageBoxData[];
}

export default class Milestones extends React.Component<MilestonesProps, MilestonesState> {
  public state: MilestonesState = {
    isLoading: false,
    entries: [],
  };

  public async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const entries = await getData('milestones');
      this.setState({ entries, isLoading: false });
    } catch (e) {
      // window.location.reload()
    }
  }

  public render() {
    const { isLoading, entries } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      <LanguageContext>
        <LangContext.Consumer>
          {(ctx) => (
            <>
              <Helmet />
              <ExtraPagesMain title={ctx.lang === 'en' ? 'Milestones' : 'Istoric'} data={entries} />
            </>
          )}
        </LangContext.Consumer>
      </LanguageContext>
    );
  }
}
